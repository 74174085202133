import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';
import {Router} from '@angular/router';
import {concatMap, tap, withLatestFrom} from 'rxjs/operators';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  invalidLogin: boolean;
  dispErr= false;
  constructor(private router: Router, private http:HttpClient) { }
  
  username: string;
  password: string;
  showSpinner: false;

  text: string;
  readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };



  ngOnInit() {
  }

  readFromApi(fileName: string, pass: string  ) : void
  {
    const prms =  '"' + fileName + '"';
    let prms2 = JSON.stringify({name: fileName, pass: pass});
    this.http.post<FText[]>(environment.apiUrl + '/api/login/', prms2, this.httpOptions).subscribe(result => {

        const token = (<any>result).token;
        localStorage.setItem("jwt", token);
        this.invalidLogin = false;
        this.router.navigate(["show"]);
        sessionStorage.usnm=fileName;
      }, err => {
        this.invalidLogin = true;
        if( err.status == 401)
          this.dispErr = true;          
        else
        alert( "103: Service unavailabail. Please try again later");
        //console.log("Invalid credentials" + JSON.stringify(err.status) );
      },() => {
          //always call
          //alert( "103: Service unavailabail. Please try again later");
      }      
      );      



      /*
      this.text = result[0].con;
      //console.log("***"+ this.text+"****");

      //if(this.username == 'admin' && this.password == 'admin123'){
      if( this.text == "yes"){

        this.router.navigate(['show']);
   
       }else {
   
        this.router.navigate(['']);
        //alert("Invalid credentials");
        console.log("Invalid credentials");
        this.dispErr= true;
   
       }   
    }
    ,error => {
      alert( "103: Service unavailabail. Please try again later");
  }
    );      
      */

      
      //console.log("***"+ this.text+"****");

      //if(this.username == 'admin' && this.password == 'admin123'){

      

  //     if( this.text == "yes"){

  //       this.router.navigate(['show']);
   
  //      }else {
   
  //       this.router.navigate(['']);
  //       //alert("Invalid credentials");
  //       console.log("Invalid credentials");
  //       this.dispErr= true;
   
  //      }   
  //   }
  //   ,error => {
  //     alert( "103: Service unavailabail. Please try again later");
  // }
  //   );
  }

  // readFromApiSync(fileName: string ) : void
  // {
  //    const prms =  '"' + fileName + '"';
  //   // this.http.post<FText[]>(environment.apiUrl + '/api/login/', prms, this.httpOptions).pipe(
  //   //   tap(res => console.log('First result', res[0].con))
  //   // );
  //   this.http.post<FText[]>(environment.apiUrl + '/api/login/', '"' + fileName + '"', this.httpOptions)
  //   .pipe(
  //     tap(res => console.log('First result', res)),
  //     concatMap((res: { timeout: number }) => this.http.post<FText[]>(environment.apiUrl + '/api/login/', '"' + fileName +'1'+ '"', this.httpOptions)),
  //     tap(res => console.log('Second result', res)),
  //     concatMap((res: { timeout: number }) => this.http.post<FText[]>(environment.apiUrl + '/api/login/', '"' + fileName + '2' + '"', this.httpOptions)),
  //     tap(res => console.log('Third result', res)),
  //   )
  //   .subscribe(res => console.log('Latest result', res));
  // }

  demoF(){
    console.log('***test demo***');
    return "12345";
  }

  // data;
  // checkLogin(name):Observable<boolean>{
  //   return this.readFromApi(name)
  //                      .map(response => {  
  //                         this.data = response;                            
  //                         //this.checkservice=true;
  //                         return true;
  //                      })
  //                      .catch(error => {
  //                         this.router.navigate(['login']);
  //                         console.log(error);
  //                         return Observable.throw(error);
  //                      })
  //  }

  login() : void {  

    console.log(" **********" + environment.ver )    ;
    this.readFromApi(this.username, this.password);
    //this.checkLogin(this.username);
    //console.log("test " + this.text)    ;

    // if(this.username == 'admin' && this.password == 'admin123'){

    //  this.router.navigate(['presentation/show']);

    // }else {

    //   alert("Invalid credentials");

    // }

  }

  
  onKey(event: any) { // without type info
    //this.values += event.target.value + ' | ';
    this.dispErr= false;
    if( event.key == "Enter")
    {
      this.login();
    }
  }

}
interface FText {
  con: string;
}
