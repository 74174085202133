<section fxLayout="column" fxFlexAlign="stretch">
    <div fxFlexAlign="center">
      <div class="welcome">Welcome to <div class="header">We Connect Fast . Com</div></div> 
      <div>An Israeli startup.</div>
      <br>
      <div>For investments at our venture please leave a message at <a href="mailto:success@weconnectfast.com"><span>success@weconnectfast.com</span></a>.</div>
      Thank you      
      <br><br>
      <div>
        <a href="http://weconnectfast.com/">Enter</a>
      </div>    
      </div>

      
  </section>
