<mat-toolbar color="primary" style="position:fixed; top:0; z-index: 1000000;" >
    <div fxHide.gt-xs>
        <button mat-icon-button (click)="onToggleSidenav()">
            <mat-icon>menu</mat-icon>
        </button>
    </div>
    <div>
        <a >{{ownerName}}-Account</a>
    </div>
    <div fxFlex fxLayout fxLayoutAlign="end" fxHide.xs>
        <ul fxLayout fxLayoutGap="15px" class="navigation-items">
            <li>
                <a routerLink="/note" [routerLinkActive]="['active']">Sharply</a>
            </li>
            <li>
                <a routerLink="/show" [routerLinkActive]="['active']">Intro</a>
            </li>
            <li>
                <a routerLink="/vision" [routerLinkActive]="['active']">Vision</a>
            </li>
            <li>
                <a routerLink="/market" [routerLinkActive]="['active']">Market</a>
            </li>
            <li>
                <a routerLink="/models" [routerLinkActive]="['active']">Models</a>
            </li>
            <li>
                <a routerLink="/exit" [routerLinkActive]="['active']">Exit</a>
            </li>
            <li>
                <a routerLink="/financial" [routerLinkActive]="['active']">Financial</a>
            </li>
            <li>
                <a routerLink="/competitors" [routerLinkActive]="['active']">Competitors</a>
            </li>
            <li>
                <a routerLink="/status" [routerLinkActive]="['active']">Status</a>
            </li>
            <li>
                <a routerLink="/team" [routerLinkActive]="['active']">Team</a>
            </li>
            <li>
                <a routerLink="/contact" [routerLinkActive]="['active']">Contact</a>
            </li>
            <li>
                <a [routerLink]="['/']" class="med-text" (click)="logout()" ><mat-icon>lock_open</mat-icon>Logout </a>
            </li>
        </ul>
    </div>
  </mat-toolbar>