<section fxLayout="column" fxFlexAlign="stretch">
    <div fxFlexAlign="center">
  
    <mat-toolbar color="primary">

        <mat-toolbar-row>
      
        <span class="login-custom">Log in</span>
        
            <span class="example-fill-remaining-space"></span>
        
            <span class="align-center"></span>
        
            <span class="example-spacer"></span>
        
            <!-- <button mat-button>About</button> -->
        
            <!-- <button mat-button>Contact</button> -->
        
            <!-- <button mat-icon-button [matMenuTriggerFor]="menu">
        
          <mat-icon>more_vert</mat-icon>
        
        </button> -->
        
        <mat-menu #menu="matMenu">
        
          <button mat-menu-item>
        
            <mat-icon>dialpad</mat-icon>
        
            <span>Redial</span>
        
          </button>
        
          <button mat-menu-item disabled>
        
            <mat-icon>voicemail</mat-icon>
        
            <span>Check voicemail</span>
        
          </button>
        
          <button mat-menu-item>
        
            <mat-icon>notifications_off</mat-icon>
        
            <span>Disable alerts</span>
        
          </button>
        
        </mat-menu>
        
      
      
         </mat-toolbar-row>
       
        </mat-toolbar> 
      
        
        <table  width="100%" >
          <tr>
            <td class="tcol"></td>
            <td style="text-align: center;" class="tcol">
              <div class="maincell" style="text-align:unset;" >
                <div class="lgcard" style="flex: 1 1 auto;">
                  <mat-card class="example-card login-custom">
              
                  <mat-card-header>
               
                    <mat-card-title >Login</mat-card-title>
               
                  </mat-card-header>
               
              
                  <mat-card-content>
               
                    <form class="example-form">
               
                      <table class="example-full-width" cellspacing="0">
               
                        <tr>
               
                          <td>
               
                            <mat-form-field class="example-full-width">
               
                            <input matInput placeholder="Username" [(ngModel)]="username" name="username" (keyup)="onKey($event)" required autofocus>
               
                            </mat-form-field>
               
                          </td>
               
                        </tr>
               
                        <tr>
               
                        <td><mat-form-field class="example-full-width">
               
                          <input matInput placeholder="Password" [(ngModel)]="password"type="password" name="password" (keyup)="onKey($event)"  required>
               
                        </mat-form-field></td>
               
                      </tr>
                      <tr>
                        <div *ngIf="dispErr" class="login-err">Invalid login. Please change user name or password</div>
                      </tr>
                    </table>
               
                    </form>
               
                    <mat-spinner [style.display]="showSpinner ? 'block' : 'none'"></mat-spinner>
               
                  </mat-card-content>
               
                  <mat-card-actions>
               
                    <button mat-raised-button (click)="login()" class="login-custom" color="primary">Login</button>
               
                  </mat-card-actions>
               
                </mat-card>
              </div>
                        
              </div>  
            </td>
            <td class="tcol"></td>
          </tr>
          <tr>
              <td></td>
              <td >
              <div class="text-notice">
                This information intended only for the person(s) it sends for.
                This text contains information that is confidential, privileged and/or exempt from disclosure under applicable law.     
              </div>

          </td></tr>
        </table>
      <!-- <div class="text-notice-env">
      <div class="text-notice">
      </div>
      </div> -->
      <mat-toolbar color="primary">
      
        <mat-toolbar-row>
      
        <span class="copyright login-custom-title">Copyright © 2020 RYMA</span>
        <div class="contactus"><a href="mailto:success@weconnectfast.com"><span class="email-login login-custom">Contact us</span></a></div>
      
      
         </mat-toolbar-row>
       
        </mat-toolbar> 
      
    </div>
  </section>