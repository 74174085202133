import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements AfterViewInit {

  constructor(private jwtHelper: JwtHelperService, private router: Router) { }


  public executeSelectedChange = (event) => {
    console.log(event);
  }

  isUserAuthenticated() {
    const token: string = localStorage.getItem("jwt");
    if (token && !this.jwtHelper.isTokenExpired(token)) {
      return true;
    }
    else {
      return false;
    }
  }
  public logOut = () => {
    localStorage.removeItem("jwt");
  }
 
  ngAfterViewInit(): void {
    // document.getElementsByTagName("img")[0].onclick((event)=>{
    //   event.target.classList.toggle('img-larger');
    // })

    var elemts = document.getElementsByTagName("img");
    // alert(document.getElementsByTagName("img").length);
    //for(let i=0; i<elem.length)
    for (let y=0; y<elemts.length; ++y){
    // //   var elem= document.getElementsByTagName("img")[y];
    // //   elem.addEventListener('click', (e) => {
    // //     //this.navigate();//your typescript function
    // //    // e.target.classList.toggle('img-larger');
    // //    this.onImgClick(elem);
    // //    //this.onImgClick(document.getElementsByTagName("img")[1]);
    // // });
  
    document.getElementsByTagName("img")[y].addEventListener('click', (e) => {
      this.onImgClick(document.getElementsByTagName("img")[y]);
   });
   document.getElementsByTagName("img")[y].addEventListener('tap', (e) => {
    this.onImgClick(document.getElementsByTagName("img")[y]);
 });

    }
//     document.getElementsByTagName("img")[0].addEventListener('click', (e) => {
//       //this.navigate();//your typescript function
//      // e.target.classList.toggle('img-larger');
//      this.onImgClick(document.getElementsByTagName("img")[0]);
//      //this.onImgClick(document.getElementsByTagName("img")[1]);
//   });
//   document.getElementsByTagName("img")[1].addEventListener('click', (e) => {
//     //this.navigate();//your typescript function
//    // e.target.classList.toggle('img-larger');
//    this.onImgClick(document.getElementsByTagName("img")[1]);
//    //this.onImgClick(document.getElementsByTagName("img")[1]);
// });
//  alert(document.getElementsByTagName("img")[0].onclick());
    
  }

  onImgClick(e){
    //e.target.classList.toggle('img-larger'); // To toggle
    e.classList.toggle('img-larger'); // To toggle
  }

  clicked(event) {
    event.target.classList.add('class3'); // To ADD
    event.target.classList.remove('class1'); // To Remove
    event.target.classList.contains('class2'); // To check
    event.target.classList.toggle('class4'); // To toggle
  }
}
