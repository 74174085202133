import { Component, OnInit,  HostListener } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-competitors',
  templateUrl: './competitors.component.html',
  styleUrls: ['./competitors.component.scss']
})
export class CompetitorsComponent implements OnInit {
  text: string;
  readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient) { }

  ngOnInit() {
    // this.readFromFile('competitors').then( (data) => {
    //   this.text = data;
    // });
    this.readFromApi('competitors');
  }

  readFromApi(fileName: string ) : void
  {
    const prms =  '"' + fileName + '"';
    this.http.post<FText[]>(environment.apiUrl + '/api/data/', prms, this.httpOptions).subscribe(result => {
      this.text = result[0].con;
    });
  }

  async readFromFile(fileName: string ): Promise<string>
  {
    const text =  await this.http.get('../../../assets/data/' + fileName + '.txt', {responseType: 'text'}).toPromise();
    return text;
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

}

interface FText {
  con: string;
}
