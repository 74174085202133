<!-- <div style="position:fixed; top:0; z-index: 1000000;width:100%;"> -->
  <app-header (sidenavToggle)="sidenav.toggle()"  [ngClass]="{'not-show-navbar':!showNavbar}"  ></app-header>
<!-- </div> -->
<app-layout>
  <mat-sidenav-container>
    <mat-sidenav #sidenav role="navigation" class="specific-container">
      <app-sidenav-list (sidenavClose)="sidenav.close()"></app-sidenav-list>
    </mat-sidenav>
    <mat-sidenav-content class="specific-content">      
        <main>
        <div class="title-box" [ngClass]="{'not-show-navbar':!showNavbar}" ><div class="cont-ya"><div class="page-sub-title">{{pageTitle}}</div></div></div>
        <router-outlet></router-outlet>
      </main>
    </mat-sidenav-content>
  </mat-sidenav-container>
</app-layout>