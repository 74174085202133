import { Component, OnInit, HostListener } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../environments/environment';


@Component({
  selector: 'app-vision',
  templateUrl: './vision.component.html',
  styleUrls: ['./vision.component.scss']
})
export class VisionComponent implements OnInit {

  readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  text: string;

  constructor(private http:HttpClient) { }

  ngOnInit() {
    // this.readFromFile('vision').then((data) => {
    //   this.text = data;
    // });

    this.readFromApi('vision');

    // var prms = "'vision'";
    // this.http.post<FText[]>('http://localhost:5000/api/data/', prms, this.httpOptions).subscribe(result => {
    //   this.text = result[0].con;
    // });
  }

  readFromApi(fileName: string ) : void
  {
    const prms =  '"' + fileName + '"';
    this.http.post<FText[]>( environment.apiUrl + '/api/data/', prms, this.httpOptions).subscribe(result => {
      this.text = result[0].con;
    });
  }
  
  async readFromFile(fileName: string ): Promise<string>
  {
//    alert('test');
    //const text = "";
    //const text =  await this.http.get('../../../assets/data/' + fileName + '.txt', {responseType: 'text'}).toPromise();
    //const text =  await this.http.get('http://localhost:5000/api/data/?name=' + fileName , {responseType: 'text'}).toPromise();
    //let urlSearchParams = new URLSearchParams();
    //urlSearchParams.append('name', fileName);
    //const text =  await this.http.post('http://localhost:5000/api/data/', urlSearchParams, {responseType: 'text'}).toPromise();
    //let text;
    // this.http.post('http://localhost:5000/api/data/', urlSearchParams , this.httpOptions).subscribe(result => {
    //   text = result;
    // });
    //this.http.post( 'http://localhost:5000/api/data/', fileName, this.httpOptions );
    
    var prms = "'vision'";
    this.http.post<FText[]>('http://localhost:5000/api/data/', prms, this.httpOptions).subscribe(result => {
      //this.tst = result;
      this.text = result[0].con;
      // alert('result=' + result);
      // console.log(result[0].con);
    });


    // this.http.post('http://localhost:5000/api/values/', prms, this.httpOptions).subscribe(result => {
    //   alert('result=' + result);
    //   console.log(result);
    // });

    // console.log("before");
    // this.http.post('http://localhost:5000/api/data/', prms, this.httpOptions).subscribe(result => {
    //   //alert('result=' + result);
    //   console.log("res==>" +result);
    // });
    // console.log("after");
    return this.text;
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }
}

interface FText {
  con: string;
}
