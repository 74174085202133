import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '../presentation/home/home.component';
import { LoginComponent } from '../login/login.component';
import { ShowComponent } from '../presentation/show/show.component';
import { CompetitorsComponent } from '../presentation/competitors/competitors.component';
import { ContactComponent } from '../presentation/contact/contact.component';
import { ExitComponent } from '../presentation/exit/exit.component';
import { FinancialComponent } from '../presentation/financial/financial.component';
import { MarketComponent } from '../presentation/market/market.component';
import { ModelsComponent } from '../presentation/models/models.component';
import { StatusComponent } from '../presentation/status/status.component';
import { TeamComponent } from '../presentation/team/team.component';
import { VisionComponent } from '../presentation/vision/vision.component';
import { AuthGuard } from '../guards/auth-guard.service';
import { NoteComponent } from '../presentation/note/note.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent},
  { path: 'login', component: LoginComponent},
  { path: 'show', component: ShowComponent,  canActivate: [AuthGuard] },
  { path: 'note', component: NoteComponent,  canActivate: [AuthGuard] },
  { path: 'competitors', component: CompetitorsComponent,  canActivate: [AuthGuard] },
  { path: 'contact', component: ContactComponent, canActivate: [AuthGuard] },
  { path: 'exit', component: ExitComponent, canActivate: [AuthGuard]},
  { path: 'financial', component: FinancialComponent,  canActivate: [AuthGuard] },
  { path: 'market', component: MarketComponent, canActivate: [AuthGuard] },
  { path: 'models', component: ModelsComponent,  canActivate: [AuthGuard] },
  { path: 'status', component: StatusComponent, canActivate: [AuthGuard] },
  { path: 'team', component: TeamComponent,  canActivate: [AuthGuard] },
  { path: 'vision', component: VisionComponent,  canActivate: [AuthGuard] },
  { path: '', redirectTo: '/login', pathMatch: 'full' }

];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class RoutingModule { }