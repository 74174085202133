<mat-nav-list class="nav-bar-custom">
    <!-- <a mat-list-item routerLink="/home" (click)="onSidenavClose()">
        <mat-icon>home</mat-icon> <span class="nav-caption">Home</span>
    </a> -->
    <a mat-list-item routerLink="/show" (click)="onSidenavClose()">
        <mat-icon>article</mat-icon> <span class="nav-caption">Intro</span>   
    </a>
    <a mat-list-item routerLink="/vision" (click)="onSidenavClose()">
        <mat-icon>brightness_medium</mat-icon> <span class="nav-caption">Vision</span>   
    </a>
    <a mat-list-item routerLink="/market" (click)="onSidenavClose()">
        <mat-icon>point_of_sale</mat-icon> <span class="nav-caption">Market</span>   
    </a>
    <a mat-list-item routerLink="/models" (click)="onSidenavClose()">
        <mat-icon>monetization_on</mat-icon> <span class="nav-caption">Models</span>   
    </a>
    <a mat-list-item routerLink="/exit" (click)="onSidenavClose()">
        <mat-icon>attach_money</mat-icon> <span class="nav-caption">Exit</span>   
    </a>
    <a mat-list-item routerLink="/financial" (click)="onSidenavClose()">
        <mat-icon>stacked_line_chart</mat-icon> <span class="nav-caption">Financial</span>   
    </a>
    <a mat-list-item routerLink="/competitors" (click)="onSidenavClose()">
        <mat-icon>how_to_reg</mat-icon> <span class="nav-caption">Competitors</span>   
    </a>
    <a mat-list-item routerLink="/status" (click)="onSidenavClose()">
        <mat-icon>device_hub</mat-icon> <span class="nav-caption">Status</span>   
    </a>
    <a mat-list-item routerLink="/team" (click)="onSidenavClose()">
        <mat-icon>supervised_user_circle</mat-icon> <span class="nav-caption">Team</span>   
    </a>
    <a mat-list-item routerLink="/contact" (click)="onSidenavClose()">
        <mat-icon>contacts</mat-icon> <span class="nav-caption">Contact</span>   
    </a>
    <a mat-list-item routerLink="/" (click)="onSidenavCloseAndLogout()">
        <mat-icon>lock_open</mat-icon> <span class="nav-caption">Logout</span>   
    </a> 
  </mat-nav-list>