import { Component, OnInit, HostListener, AfterViewInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { TestBed } from '@angular/core/testing';

@Component({
  selector: 'app-financial',
  templateUrl: './financial.component.html',
  styleUrls: ['./financial.component.scss']
})
export class FinancialComponent implements OnInit {

  text: string;
  readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http:HttpClient) { }

  ngOnInit() {
    // this.readFromFile('financail').then((data) => {
    //   this.text = data;
    // });
    this.readFromApi('financail');
  }

  readFromApi(fileName: string ) : void
  {
    const prms =  '"' + fileName + '"';
    this.http.post<FText[]>( environment.apiUrl + '/api/data/', prms, this.httpOptions).subscribe(result => {
      this.text = result[0].con;      
      //setTimeout(this.ngAfterView_Custom, 10);
    });

  }

  
async readFromFile(fileName: string ): Promise<string>
{
  const text =  await this.http.get('../../../assets/data/' + fileName + '.txt', {responseType: 'text'}).toPromise();
  return text;
}

@HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
  e.preventDefault();
}



public onImgClick(e){
  //alert("click");
  //e.target.classList.toggle('img-larger'); // To toggle
  //e.classList.toggle('img-larger'); // To toggle
}

ngAfterView_Custom(): void {
  // alert("after view init");
  // alert("src " +document.getElementsByTagName("img")[0].src);

  document.addEventListener('click', (e) => {
    //this.onImgClick(document.getElementsByTagName("img")[0]);
    //alert(  document.getElementsByTagName("img")[0].innerHTML);
    document.getElementsByTagName("img")[0].classList.toggle('img-larger');
 });
 document.addEventListener('tap', (e) => {
  document.getElementsByTagName("img")[0].classList.toggle('img-larger');
});
document.addEventListener('click', (e) => {
  //this.onImgClick(document.getElementsByTagName("img")[0]);
  document.getElementsByTagName("img")[1].classList.toggle('img-larger');
});

  var elemts = document.getElementsByTagName("img");
//   for (let y=0; y<elemts.length; ++y){
//   //var elem1= document.getElementsByTagName("img")[0];
//   document.addEventListener('click', (e) => {
//     //this.onImgClick(document.getElementsByTagName("img")[0]);
//     alert("click");
//     document.getElementsByTagName("img")[y].classList.toggle('img-larger');
//  });

 
// //  document.getElementsByTagName("img")[y].addEventListener('tap', (e) => {
// //   this.onImgClick(document.getElementsByTagName("img")[y]);
// // });
  
// }
}


}

interface FText {
  con: string;
}
