import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Output() public sidenavToggle = new EventEmitter();
  ownerName = "Owner";

  constructor() { }

  ngOnInit() {
    this.ownerName = sessionStorage.usnm?.charAt(0).toUpperCase() + sessionStorage.usnm?.slice(1);
  }

  logout(){
    localStorage.removeItem("jwt");
  }

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  }

}