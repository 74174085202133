<section fxLayout="column" fxFlexAlign="stretch">
    <div fxFlexAlign="center">
        <div class="main">
            <div class="para">
              <div class="decor" [innerHTML]="text"></div>
            </div>
        </div>
    </div>
</section>
    
