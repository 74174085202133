import { Component, HostListener } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  {
  title = 'App Presentation';
  showNavbar = false;
  pageTitle="ini";
  constructor(private router: Router, private location: Location){
    router.events.subscribe(val => {
        this.showNavbar = true;
        const  path = location.path();
        sessionStorage.yaurl = path;
        //alert(path);
        console.log("path app ctor =" + path);
        if (( path === '') || ( path === '/login') || ( path === '/home'))
        {
            this.showNavbar = false;
            sessionStorage.firstTimeLoad = true;
            this.pageTitle="";
            //alert( " app comp ctor = sessionStorage.firstTimeLoad" + sessionStorage.firstTimeLoad);
        }else{
            var p = path.slice(1);
            switch(p)
            {
              case "vision":{
                this.pageTitle = "Vision"                ;
                break;
              }
              case "show":{
                this.pageTitle = "Introduction";
                break;
              }
              case "note":{
                this.pageTitle = "Sharply";
                break;
              }
              case "market":{
                this.pageTitle = "Go to Market";
                break;
              }
              case "models":{
                this.pageTitle = "Business Models";
                break;
              }
              case "exit":{
                this.pageTitle = "Exit Strategy";
                break;
              }
              case "financial":{
                this.pageTitle = "Finnancial Plan";
                break;
              }
              case "competitors":{
                this.pageTitle = "Competitors";
                break;
              }
              case "status":{
                this.pageTitle = "Ventures Status";
                break;
              }
              case "team":{
                this.pageTitle= "The Team";
                break;
              }
              case "contact":{
                this.pageTitle = "Contact";
                break;
              }
            }

        }
    });
  }

  // ngAfterViewInit(){
  //   alert("test");
  // }
  
  ngAfterViewChecked(){
    //alert(sessionStorage.yaurl);
    //alert("test");
    //alert(" firstTimeLoad= " + sessionStorage.firstTimeLoad);

    console.log("sessionStorage.yaurl ngAfterViewChecked =" +sessionStorage.yaurl);
    if(sessionStorage.yaurl === '/show')
    {
      //alert("reload before  firstTime=" + sessionStorage.firstTimeLoad);      
      if ( sessionStorage.firstTimeLoad === "true")
      {
        sessionStorage.firstTimeLoad=false;
        //alert("reload");
        window.location.reload();
      }
     }
  }
  
  ngOnInit()
  {    
    console.log("app comp ngOnInit ");
    // console.log("TEST app comp "  + window.location.pathname + window.location.pathname.length );
    if(( window.location.pathname.length < 2 ) 
        || (window.location.pathname.indexOf('login') > 0  )){
      this.showNavbar = true ;
    }
    else     {
      this.showNavbar = true;
    }

    // console.log(" ngOnInit sessionStorage.yaurl ");
    // if(sessionStorage.yaurl === '/login')
    // {
    //   sessionStorage.yaurl = '';
    //   window.location.reload();
    // }

  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }
}
