<section fxLayout="column" fxFlexAlign="stretch">
    <div fxFlexAlign="center">
    <div class="main">
    <div class="para">        
        <br>
        <br><br>
        <div class="contact card">
        <div class="contact-header" >Contact Us</div><div>&nbsp;</div>
        <div class="text-contact"><div class="icn"><mat-icon class="icn">email</mat-icon><a href="mailto:success@weconnectfast.com">success@weconnectfast.com</a></div></div>      
        <div class="text-contact phone-number"><div class="icn"><mat-icon class="icn">phone</mat-icon><a href="tel:+972 (0)507304040">+972 (0)507304040</a></div></div>
        </div>
        <div>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </div>
        <!-- <div>Leave a Message</div>     
        <div class="message">
          <textarea class="text"></textarea>
        </div>
        <div class="btn">
          <button class="big">Send</button>
        </div> -->
      </div>
</div>
</div>
</section>
