import { Component, OnInit, HostListener } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {

  team: string;

  readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http:HttpClient) { }

  ngOnInit() {
    // this.readFromFile("team").then( (data) => {
    //   this.team = data;
    // });
    this.readFromApi('team');
  }

  readFromApi(fileName: string ) : void
  {
    const prms =  '"' + fileName + '"';
    this.http.post<FText[]>( environment.apiUrl + '/api/data/', prms, this.httpOptions).subscribe(result => {
      this.team = result[0].con;
    });
  }

  async readFromFile(fileName: string ): Promise<string>
  {
    const text =  await this.http.get('../../../assets/data/' + fileName + '.txt', {responseType: 'text'}).toPromise();
    return text;
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

}

interface FText {
  con: string;
}
